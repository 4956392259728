@import '../node_modules/normalize.css/normalize.css';

body {
  font-family: monospace;
}
.center {
  text-align: center;
}
.float-right {
  float: right;
}
/* Common elements */
nav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1em;
  background-color: white;
}

header {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 1rem 0;
  border-bottom: 1px solid black;
  box-shadow: 0px 5px 5px #00000040;
}
header h1 {
  margin: 0
}

/* Main drawing page */
#menu {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}
canvas {
  touch-action: none;
}

/* Pages showing the drawings */
.drawing {
  display: inline-block;
  text-align: center;
  margin: 1em;
}
.drawing .frame {
  border: 10px solid black;
}
.drawing .matte {
  border: 30px solid whitesmoke;
}
.drawing img {
  background-color: white;
  max-width: 100%;
  object-fit: scale-down;
}
.drawing a {
  text-decoration: none;
}

/* Styles for mobiie */
@media screen and (max-width: 30em) {
  header {
    position: relative;
  }
  header h1 {
    font-size: 1.4em;
    padding-top: 1.5em;
  }
}
